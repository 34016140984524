import {Component, DestroyRef, inject, OnInit, ViewChild} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {filter, switchMap} from 'rxjs/operators';
import {DocumentDto, ReceivedDocumentDto} from '|api/document';
import {
  CodebookService,
  decrement,
  DigitalComponentOperationResult,
  DigitalComponentOperationType,
  DigitalComponentView,
  DocumentDetailCountType,
  DocumentDetailService,
  ELASTIC_RELOAD_DELAY,
  EsslComponentActionsService,
  EsslComponentDto,
  EsslComponentModalService,
  EsslComponentSearchService,
  EsslComponentsToolbarButtonsComponent,
  EsslComponentsWithTaskTableDatasource,
  increment,
  isAuthorizedOperationGranted,
  isReceivedDocumentObject,
  load,
  ObjectDetailPart
} from '|shared';
import {DeliveryTypeDto} from '|api/codebook';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {DocumentAuthorizedOperation} from '|api/core';

@Component({
  selector: 'icz-essl-components-overview',
  templateUrl: './essl-components-overview.component.html',
  styleUrls: ['./essl-components-overview.component.scss'],
})
export class EsslComponentsOverviewComponent implements OnInit {

  protected loadingService = inject(LoadingIndicatorService);
  private esslComponentModalService = inject(EsslComponentModalService);
  private documentDetailService = inject(DocumentDetailService);
  private searchService = inject(EsslComponentSearchService);
  private codebookService = inject(CodebookService);
  private activatedRoute = inject(ActivatedRoute);
  private destroyRef = inject(DestroyRef);
  private router = inject(Router);

  @ViewChild(EsslComponentsToolbarButtonsComponent)
  toolbar!: EsslComponentsToolbarButtonsComponent;

  @ViewChild(EsslComponentActionsService)
  esslComponentActionsService!: EsslComponentActionsService;

  dataSource!: EsslComponentsWithTaskTableDatasource;

  selectedRows: EsslComponentDto[] = [];

  private deliveryTypes: DeliveryTypeDto[] = [];
  private queryParamsSubscription: Nullable<Subscription>;

  readonly DigitalComponentView = DigitalComponentView;

  document: Nullable<DocumentDto>;
  deliveryType: Nullable<DeliveryTypeDto>;
  isDocumentProfilePermissionGranted = false;

  ngOnInit() {
    this.codebookService.deliveryTypes().subscribe(dt => {
      this.deliveryTypes = dt;
    });

    this.documentDetailService.object$.pipe(
      filter(Boolean),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(doc => {
      this.document = doc as DocumentDto;
      this.isDocumentProfilePermissionGranted = isAuthorizedOperationGranted(doc.authorization, DocumentAuthorizedOperation.DOCUMENT_SHOW_PROFILE);

      this.dataSource = new EsslComponentsWithTaskTableDatasource(this.searchService, doc.id!, !this.isDocumentProfilePermissionGranted);

      if (isReceivedDocumentObject(this.document)) {
        this.deliveryType = this.deliveryTypes.find(dt => dt.id === (doc as ReceivedDocumentDto).deliveryTypeId);
      }

      if (!this.queryParamsSubscription) {
        this.queryParamsSubscription = this.activatedRoute.queryParamMap.pipe(
          filter(queryParams => {
            return queryParams.has('digitalComponentId');
          }),
          switchMap(queryParams => this.esslComponentModalService.openEsslComponentDetailWithFullFetch(
            Number(queryParams.get('digitalComponentId')),
            Number(queryParams.get('digitalComponentVersionId')),
            this.document!.id,
            false,
            false,
          )),
          takeUntilDestroyed(this.destroyRef),
        ).subscribe(metadataChanged => {
          const routeWithoutQueryParams = this.router.url.replace(/\?.+$/g, '');
          this.router.navigateByUrl(routeWithoutQueryParams);

          if (metadataChanged) {
            this.dataSource.reload(true);
          }
        });
      }
    });

    this.documentDetailService.showSubmitBar = false;
  }

  digitalComponentOperationCompleted(result: DigitalComponentOperationResult) {
    setTimeout(() => {
      switch (result.operationType) {
        case DigitalComponentOperationType.COMPONENT_ADDED:
        case DigitalComponentOperationType.COMPONENT_DELETED:
        case DigitalComponentOperationType.FORM_FILLER_CLAUSE_SUCCESS:
          if (!isNil(result.addedComponentCount) || !isNil(result.removedComponentCount)) {
            if (!isNil(result.addedComponentCount)) {
              this.documentDetailService.reloadObject({
                [ObjectDetailPart.OBJECT_DATA]: load(),
                [DocumentDetailCountType.COMPONENTS]: increment(result.addedComponentCount),
              });
            } else if (!isNil(result.removedComponentCount)) {
              this.documentDetailService.reloadObject({
                [ObjectDetailPart.OBJECT_DATA]: load(),
                [DocumentDetailCountType.COMPONENTS]: decrement(result.removedComponentCount),
              });
            }
            this.dataSource.reload(true);
          }
          break;
        case DigitalComponentOperationType.COMPONENTS_DELETED:
          this.documentDetailService.reloadObject({
            [DocumentDetailCountType.COMPONENTS]: load(),
          });
          this.dataSource.reload(true);
          break;
        case DigitalComponentOperationType.CIRCULATION_STARTED:
          this.documentDetailService.reloadObject({
            [ObjectDetailPart.OBJECT_DATA]: load(),
            [DocumentDetailCountType.ACTIVITIES]: load(),
          });
          this.dataSource.reload(true);
          break;
        case DigitalComponentOperationType.ANONYMIZATION:
          this.documentDetailService.reloadObject({
            [DocumentDetailCountType.COMPONENTS]: load(),
          });
          this.dataSource.reload(true);
          break;
        case DigitalComponentOperationType.COMPONENT_VERSION_ADDED:
        case DigitalComponentOperationType.COMPONENT_CHECKED_IN:
        case DigitalComponentOperationType.OUTPUT_FORMAT_CONVERSION:
        case DigitalComponentOperationType.CONVERSION_WITH_CLAUSE:
        case DigitalComponentOperationType.ADD_TIMESTAMP:
        case DigitalComponentOperationType.MARK_DIGITAL_COMPONENT:
        case DigitalComponentOperationType.SIGN_DIGITAL_COMPONENT:
        case DigitalComponentOperationType.COMPONENT_LOCKED:
        case DigitalComponentOperationType.COMPONENT_UNLOCKED:
        case DigitalComponentOperationType.COMPONENT_CHECKED_OUT:
        case DigitalComponentOperationType.DIGITAL_RENDITION_ADDED:
        case DigitalComponentOperationType.CIRCULATION_ACTION_COMPLETED:
        case DigitalComponentOperationType.MANUAL_DIGITAL_COMPONENT_VALIDATION:
        case DigitalComponentOperationType.AUTHORIZED_CONVERSION_STARTED:
        case DigitalComponentOperationType.VERIFIED_CONVERSION_STARTED:
        case DigitalComponentOperationType.EXECUTION_CLAUSE_CREATED:
        case DigitalComponentOperationType.EXTRACTION:
        case DigitalComponentOperationType.NATIVE_EDIT:
        case DigitalComponentOperationType.COMPONENT_TEMPLATE_UPDATED:
          this.dataSource.reload(true);
          break;
      }
    }, ELASTIC_RELOAD_DELAY);

  }

  onRowsSelected(data: EsslComponentDto[]) {
    this.selectedRows = data;
  }

  protected readonly DocumentAuthorizedOperation = DocumentAuthorizedOperation;
  protected readonly isAuthorizedOperationGranted = isAuthorizedOperationGranted;

}
