import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {
  EsslComponentsOverviewComponent
} from './essl-components-overview.component';
import {
  EsslComponentsRoutingModule
} from './essl-components-routing.module';
import {SharedModule} from '|shared';

@NgModule({
  declarations: [EsslComponentsOverviewComponent],
  imports: [CommonModule, SharedModule, EsslComponentsRoutingModule],
})
export default class EsslComponentsOverviewModule {}
