@if (dataSource) {
  <icz-essl-components-table
    id="essl-components-overview-table"
    class="grow h-full"
    [document]="document"
    [dataSource]="dataSource"
    [isNonBlockingLoading]="esslComponentActionsService ? loadingService.isLoading(esslComponentActionsService) : false"
    [isConstrainedView]="!isDocumentProfilePermissionGranted"
    (rowsSelected)="onRowsSelected($event)"
    (contextMenuRequested)="toolbar.openContextMenu($event)"
  >

    <icz-label tableTitle label="Komponenty"></icz-label>

    @if (document && isDocumentProfilePermissionGranted) {
      <icz-essl-components-toolbar-buttons
        tools
        [parentDocument]="document"
        [deliveryType]="deliveryType"
        [view]="DigitalComponentView.DIGITAL_COMPONENT_LISTING"
        [selectedRows]="selectedRows"
        (operationCompleted)="digitalComponentOperationCompleted($event)"
      ></icz-essl-components-toolbar-buttons>
    }

  </icz-essl-components-table>
}
